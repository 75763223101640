import Vue from 'vue'
import Router from 'vue-router'
import {dataService} from "@/_services";
// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

// Users
Vue.use(Router)


const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  linkExactActiveClass: 'active',
  routes: [
     {
      path: '/dashboard',
      redirect: 'dashboard',
      name: 'Home',
      component: TheContainer,
      children: [

        {
          path: '/dashboard',
          name: 'dashboard',
          component: () => import('@/views/Dashboard.vue'),
            meta: {
                permission: [1,2,4,9]
            }
        },
        {
          path: '/icons',
          name: 'icons',
          component: () => import('@/views/icons/CoreUIIcons.vue'),
            meta: {
                permission: [1]
            }
        },
          {
            path: '/closerange',
            name: 'closerange',
            component: () => import('@/views/CloseRange.vue'),
            meta: {
              permission: [1,2]
            }
          },{
            path: '/campaigns',
            name: 'campaigns',
            component: () => import('@/views/Campaign.vue'),
            meta: {
              permission: [1,2]
            }
          },{
            path: '/redirects',
            name: 'redirects',
            component: () => import('@/views/Redirects.vue'),
            meta: {
              permission: [1,2]
            }
          },
          {
            path: '/edit-campaign/:id',
            name: 'edit-campaign',
            props:true,
            component: () => import('@/views/CampaignEdit.vue'),
            meta: {
              permission: [1,2]
            }
          },
          {
            path: '/edit-document/:id',
            name: 'edit-document',
            props:true,
            component: () => import('@/views/DocumentEdit.vue'),
            meta: {
              permission: [1,2]
            }
          },
          {
            path: '/multiedit',
            name: 'multiedit',
            props: true,
            component: () => import('@/views/MultiEdit.vue'),
              meta: {
                  permission: [1,2]
              }
          },
          {
            path: '/multiedittaxonomy',
            name: 'multiedittaxonomy',
            props: true,
            component: () => import('@/views/MultiEditTaxo.vue'),
              meta: {
                  permission: [1,2]
              }
          },
          {
            path: '/pricecompare',
            name: 'pricecompare',
            component: () => import('@/views/PriceCompare.vue'),
            meta: {
              permission: [1,2]
            }
          },
        {
          path: '/not-found',
          name: 'not-found',
          component: () => import('@/views/NotFound.vue')
        },
        {
          path: '/content-types',
          name: 'content-types',
          component: () => import('@/views/ContentTypes.vue'),
            meta: {
                permission: [1]
            }
        },
        {
          path: '/tree-taxonomy-items/:id',
          name: 'tree-taxonomy-items',
          props: true,
          component: () => import(/* webpackChunkName: "ntademo" */ '@/views/content/TreeItems.vue'),
            meta: {
                permission: [1,2]
            }
        },
          {
            path: '/taxonomy',
            name: 'taxonomy',
            component: () => import('@/views/TaxonomyVoc.vue'),
            meta: {
              permission: [1,2]
            }
          },{
            path: '/skipass',
            name: 'skipass',
            component: () => import('@/views/SkiPass.vue'),
            meta: {
              permission: [1,2]
            }
          },
        {
          path: '/blocks',
          name: 'blocks',
          component: () => import('@/views/Blocks.vue'),
            meta: {
                permission: [1]
            }
        },
          {
            path: '/block/:id',
            name: 'block',
            props:true,
            component: () => import('@/views/Block.vue'),
            meta: {
              permission: [1]
            }
          }, {
            path: '/roomprice/:id',
            name: 'roomprice',
            props:true,
            component: () => import('@/views/roomPrice.vue'),
            meta: {
              permission: [1,2]
            }
          },
          {
            path: '/packageprice/:id',
            name: 'packageprice',
            props:true,
            component: () => import('@/views/packagePrice/packagePrice.vue'),
            meta: {
              permission: [1,2]
            }
          },
        {
          path: '/crange/:id',
          name: 'crange',
          props:true,
          component: () => import('@/views/CRange.vue'),
            meta: {
                permission: [1,2]
            }
        },
        {
          path: '/menu',
          name: 'content-menu',
          component: () => import('@/views/content/ContentMenu.vue'),
            meta: {
                permission: [1]
            }
        },
        {
          path: '/menu/:id',
          name: 'menu-edit',
          props: true,
          component: () => import('@/views/content/AddMenu.vue'),
            meta: {
                permission: [1]
            }
        },
          {
            path: '/menu-items/:id',
            name: 'menu-items',
            props: true,
            component: () => import('@/views/content/MItems.vue'),
            meta: {
              permission: [1]
            }
          }, {
            path: '/deskrental',
            name: 'desk-rental',
            props: true,
            component: () => import('@/views/DeskRental.vue'),
            meta: {
              permission: [1]
            }
          },
            {
                path: '/denied',
                name: 'denied',
                component: () => import('@/views/Denied.vue'),
            },
            {
          path: '/menu-items-reorder/:id',
          name: 'menu-items-reorder',
          props: true,
          component: () => import('@/views/content/OMItems.vue'),
                meta: {
                    permission: [1]
                }
        },
        {
          path: '/form-items/:id',
          name: 'form-items',
          props: true,
          component: () => import('@/views/content/FItems.vue'),
            meta: {
                permission: [1,2]
            }
        },
        {
          path: '/submissions/:id',
          name: 'submissions',
          props: true,
          component: () => import('@/views/Submissions.vue'),
            meta: {
                permission: [1,2]
            }
        },
        {
          path: '/taxonomy-items/:id',
          name: 'taxonomy-items',
          props: true,
          component: () => import('@/views/content/TItems.vue'),
            meta: {
                permission: [1,2]
            }
        },
        {
          path: '/menu-item/:mid/:id',
          name: 'menu-item',
          props: true,
          component: () => import('@/views/content/MItem.vue'),
            meta: {
                permission: [1]
            }
        },

        {
          path: '/form-item/:mid/:id',
          name: 'form-item',
          props: true,
          component: () => import('@/views/content/FormItem.vue'),
            meta: {
                permission: [1]
            }
        },
        {
          path: '/invoices',
          name: 'invoices',
          component: () => import('@/views/Invoices.vue'),
            meta: {
                permission: [1]
            }
        },
        {
          path: '/partners',
          name: 'partners',
          component: () => import('@/views/Partners.vue'),
            meta: {
                permission: [1]
            }
        },
        {
          path: '/invoices-services',
          name: 'invoices-services',
          component: () => import('@/views/InvoicesServices.vue'),
            meta: {
                permission: [1]
            }
        },
        {
          path: '/template',
          name: 'template',
          component: () => import('@/views/Template.vue'),
                meta: {
                    permission: [1]
                }
        },
          {
            path: '/documents',
            name: 'documents',
            component: () => import('@/views/Documents.vue'),
            meta: {
              permission: [1]
            }
          },
          {
            path: '/languages',
            name: 'languages',
            component: () => import('@/views/Languages.vue'),
            meta: {
              permission: [1]
            }
          },
          {
            path: '/congress',
            name: 'congress',
            component: () => import('@/views/Congress/Congress.vue'),
            meta: {
              permission: [1]
            }
          },
        {
          path: '/language/:id',
          name: 'language',
          props: true,
          component: () => import('@/views/Language.vue'),
            meta: {
                permission: [1]
            }
        },
            {
                path: '/translate/:id',
                name: 'translate',
                props: true,
                component: () => import('@/views/Translate.vue'),
                meta: {
                    permission: [1]
                }
            },
          {
            path: '/translate/:id/:tid',
            name: 'translate-taxonomy',
            props: true,
            component: () => import('@/views/Translate.vue'),
            meta: {
              permission: [1]
            }
          },
        {
          path: '/content-type/:id',
          name: 'content-type',
          props: true,
          component: () => import('@/views/ContentType.vue'),
            meta: {
                permission: [1]
            }
        },  {
          path: '/taxonomy/:id',
          name: 'taxonomyvoc',
          props: true,
          component: () => import('@/views/Taxonomy.vue'),
                meta: {
                    permission: [1]
                }
        },
        {
          path: '/add-content/:id',
          name: 'add-content',
          props: true,
          component: () => import('@/views/EditContent.vue'),
            meta: {
                permission: [1,2]
            }
        },
        {
          path: '/add-taxonomy-item/:id',
          name: 'add-taxonomy-item',
          props: true,
          component: () => import('@/views/TaxonomyItem.vue'),
            meta: {
                permission: [1,2]
            }
        },   {
            path: '/pricemaker',
            name: 'pricemaker',
            props: true,
            component: () => import('@/views/priceMaker.vue'),
            meta: {
              permission: [1,2]
            }
          },  {
            path: '/bikeprices',
            name: 'bikeprices',
            props: true,
            component: () => import('@/views/BikePrices/BikePrices.vue'),
            meta: {
              permission: [1]
            }
          },  {
            path: '/shipping',
            name: 'shipping',
            props: true,
            component: () => import('@/views/Shipping/Shipping.vue'),
            meta: {
              permission: [1]
            }
          },   {
          path: '/edit-taxonomy-item/:item/:id',
          name: 'edit-taxonomy-item',
          props: true,
          component: () => import('@/views/TaxonomyItem.vue'),
                meta: {
                    permission: [1,2]
                }
        },
        {
          path: '/edit-content/:id',
          name: 'edit-content',
          props: true,
          component: () => import('@/views/EditContent.vue'),
            meta: {
                permission: [1,2,4]
            }
        }, {
          path: '/invoice/:id',
          name: 'invoicesid',
          props: true,
          component: () => import('@/views/Invoice.vue'),
            meta: {
                permission: [1,2,4]
            }
        },
        {
          path: '/content',
          name: 'content',
          component: () => import('@/views/ContentBase.vue'),
            meta: {
                permission: [1,2]
            }
        },
        {
          path: '/content/:id',
          name: 'content-specific',
          props: true,
          component: () => import('@/views/ContentBase.vue'),
            meta: {
                permission: [1,2,4]
            }
        },
        {
          path: '/content/:id/:tid',
          name: 'content-specific-item',
          props: true,
          component: () => import('@/views/Content.vue'),
            meta: {
                permission: [1,2,4]
            }
        },
        {
          path: '/guests',
          name: 'guests',
          component: () => import('@/views/Guests.vue'),
            meta: {
                permission: [1]
            }
        },
        {
          path: '/giftcards',
          name: 'giftcards',
          component: () => import('@/views/Giftcards.vue'),
            meta: {
                permission: [1,2,9]
            }
        },
          {
            path: '/giftcardsetup',
            name: 'giftcardsetup',
            component: () => import('@/views/GiftInfo.vue'),
            meta: {
              permission: [1,2]
            }
          },
          {
            path: '/orders',
            name: 'orders',
            component: () => import('@/views/Orders.vue'),
            meta: {
              permission: [1,2]
            }
          },
          {
            path: '/listrental',
            name: 'listrental',
            component: () => import('@/views/ListRental.vue'),
            meta: {
              permission: [1,2]
            }
          },
        {
          path: '/guest/:id',
          name: 'guest',
          props: true,
          component: () => import('@/views/GuestDetail.vue'),
            meta: {
                permission: [1]
            }
        },
            {
                path: '/order/:id',
                name: 'order-edit',
                props: true,
                component: () => import('@/views/Order.vue'),
                meta: {
                    permission: [1,2,9]
                }
            },
            {
              path: '/users',
              name: 'users',
              component: () => import('@/views/Users.vue'),
                meta: {
                    permission: [1]
                }
            },
            {
              path: '/modules',
              name: 'modules',
              component: () => import('@/views/Modules.vue'),
                meta: {
                    permission: [1]
                }
            },
            {
              path: '/module/:id',
              name: 'module',
              props: true,
              component: () => import('@/views/Module.vue'),
                meta: {
                    permission: [1]
                }
            },
            {
              path: '/maps/:id',
              name: 'maps',
              props: true,
              component: () => import('@/views/Tracks.vue'),
                meta: {
                    permission: [1]
                }
            },
            {
              path: '/edit-map/:mid',
              name: 'maps-edit',
              props: true,
              component: () => import('@/views/editMap.vue'),
                meta: {
                    permission: [1]
                }
            },
             {
              path: '/add-map/:id',
              name: 'maps-add',
              props: true,
              component: () => import('@/views/editMap.vue'),
                meta: {
                    permission: [1]
                }
            },
            {
              path: '/maps/:id/:tid',
              name: 'maps-specific',
              props: true,
              component: () => import('@/views/Tracks.vue'),
                meta: {
                    permission: [1]
                }
            },
            {
          path: '/user/:id',
          name: 'user',
          props: true,
          component: () => import('@/views/User.vue'),
            meta: {
                permission: [1]
            }
        },
        {
          path: '/permissions',
          name: 'permissions',
          component: () => import('@/views/Permissions.vue'),
            meta: {
                permission: [1]
            }
        },
          {
            path: '/permission/:id',
            name: 'permission',
            props: true,
            component: () => import('@/views/Permission.vue'),
            meta: {
              permission: [1]
            }
          }, {
            path: '/managetranslations',
            name: 'managetranslations',
            props: true,
            component: () => import('@/views/manageTranslations.vue'),
            meta: {
              permission: [1]
            }
          },
        {
          path: '/roles',
          name: 'roles',
          component: () => import('@/views/Roles.vue'),
            meta: {
                permission: [1]
            }
        },
          {
            path: '/forms',
            name: 'forms',
            component: () => import('@/views/Forms.vue'),
            meta: {
              permission: [1,2]
            }
          },
          {
            path: '/seo',
            name: 'seo',
            component: () => import('@/views/Seo.vue'),
            meta: {
              permission: [1,2]
            }
          },{
            path: '/seo/:slug',
            name: 'seo-specific',
            props: true,
            component: () => import('@/views/Seo.vue'),
            meta: {
              permission: [1,2]
            }
          },
          {
            path: '/translations',
            name: 'translations',
            component: () => import('@/views/Translations.vue'),
            meta: {
              permission: [1,2]
            }
          },{
            path: '/translations/:slug',
            name: 'translations-specific',
            props: true,
            component: () => import('@/views/Translations.vue'),
            meta: {
              permission: [1,2]
            }
          },
          {
            path: '/guestlevels',
            name: 'guestlevels',
            component: () => import('@/views/GuestLevels.vue'),
            meta: {
              permission: [1]
            }
          },
          {
            path: '/level/:id',
            name: 'level',
            props: true,
            component: () => import('@/views/Level.vue'),
            meta: {
              permission: [1]
            }
          },
        {
          path: '/form/:id',
          name: 'form',
          props: true,
          component: () => import('@/views/Form.vue'),
            meta: {
                permission: [1,2]
            }
        },
          {
            path: '/role/:id',
            name: 'role',
            props: true,
            component: () => import('@/views/Role.vue'),
            meta: {
              permission: [1]
            }
          },  {
            path: '/rental-order/:id',
            name: 'rental-order',
            props: true,
            component: () => import('@/views/RentalOrder.vue'),
            meta: {
              permission: [1]
            }
          },
        {
          path: '/webinfo',
          name: 'webinfo',
          component: () => import('@/views/Settings.vue'),
            meta: {
                permission: [1]
            }
        },
        {
          path: '/setup',
          name: 'setup',
          component: () => import('@/views/Setup.vue'),
            meta: {
                permission: [1,2]
            }
        },
        {
          path: '/styles',
          name: 'imagestyles',
          component: () => import('@/views/ImageStyles.vue'),
            meta: {
                permission: [1]
            }
        },
        {
          path: '/reservations',
          name: 'reservations',
          component: () => import('@/views/Reservations.vue'),
            meta: {
                permission: [1,2]
            }
        },
        {
          path: '/profile',
          name: 'profile',
          component: () => import('@/views/UserProfile.vue'),
            },
        {
          path: '/tables',
          name: 'tables',
          component: () => import('@/views/Tables.vue'),
            meta: {
                permission: [1]
            }
        }
      ]
    },
    {
      path: '/404',
      name: 'Page404',
      component: Page404
    },
    {
      path: '/',
      redirect: 'login',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '/500',
          name: 'Page500',
          component: Page500
        },
        {
          path: '/login',
          name: 'Login',
          component: Login
        },
        {
          path: '/register',
          name: 'Register',
          component: Register
        }
      ]
    }
  ]
});

router.beforeEach((to, from, next) => {
  if(to.path === '/dashboard')
  {
    dataService.axiosPost("updateinfo", router.options.routes[0].children);
  }

const publicPages = ['/login'];
const authRequired = !publicPages.includes(to.path);
const loggedIn = localStorage.getItem('user');

if (authRequired && !loggedIn) {
return next('/login');
}

if (authRequired && loggedIn)
    {
const user = JSON.parse(loggedIn);

let paths = user.data.paths;
if(paths.includes(to.name))
{
next();
}
else {
return next('/denied');
}

 }
else
next();

});

export default router
